import { motorPaths } from "common/data/routePaths";

import React, { lazy } from "react";

const FNOL = lazy(() => import("pages/Motors/FNOL"));
const FNOLForms = lazy(() => import("pages/Motors/FNOL/FNOLForms"));

const FnolPrint = lazy(() => import("pages/Motors/FNOL/PDF/FnolPrint"));

const FnolDelegation = lazy(() => import("pages/Motors/FNOL/FnolDelegation"));

// desctructure the paths
const {
  fnol,

  fnolDelegation,
} = motorPaths;

export const motorRoutes = [
  // FNOL
  { path: fnol, component: FNOL },
  // FNOL Forms
  { path: `${fnol}/add`, component: FNOLForms },
  { path: `${fnol}/view/:id`, component: FNOLForms },
  { path: `${fnol}/print/:id`, component: FnolPrint },
  // vehicle brackets
  { path: fnolDelegation, component: FnolDelegation },
];

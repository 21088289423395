import React, { Fragment, useState } from "react";

import { Link } from "react-router-dom";
import ExportTable from "./ExportTable";
import ShowHideColumns from "./ShowHideColumns";

//i18n
import { withTranslation } from "react-i18next";

// custom hook

import useModuleActions from "hooks/useModuleActions";
import { claimBatchModuleId, productPCIId } from "utils/modulesIds";

import { AysncExportCSVModal } from "components/TP/Common/Modals";
import { buttonsTitles, claimsTypes } from "common/data";

const TableActionsButtons = ({
  handleAdd,
  addPageLink,
  isAddInNewTab,
  batchPageLink,
  showOptions,
  setShowOptions,
  columns,
  fullColumns,
  data,
  sheetName,
  sheetHeader,
  getSheetData,
  queryAddOn,
  customCSVHeader,
  customCSVData,
  customPDFHeader,
  customPDFData,
  subModuleId,
  moduleId,
  addDropDown,
  isBackPermission,
  backPageLink,
  addOnButtons,
  inCludeExportButton,
  asyncExport,
  generateCustomData,
  includeReloadButton,
  onReloadClick,
}) => {
  const {
    isAddPermission,
    isTableViewPermission,
    isTableExportPermission,
    isTableFilterPermission,
    isBatchUploadPermission,
    isAddClaimReimbursementPermission,
  } = useModuleActions(subModuleId && subModuleId);

  const [isModal, setIsModal] = useState(false);

  return (
    <Fragment>
      <div className="d-flex justify-content-md-end flex-wrap justify-content-start gap-2">
        {addOnButtons &&
          addOnButtons.length > 0 &&
          addOnButtons.map((addOn, index) => (
            <div className="text-sm-end" key={index + addOn.title}>
              {addOn.to ? (
                <Link
                  className={`btn ${addOn.bg} ${
                    addOn.disabled ? "disabled" : ""
                  }`}
                  aria-disabled={addOn.disabled}
                  tabIndex={addOn.disabled ? "-1" : "0"}
                  to={addOn.to ? addOn.to : "#"}
                  target={addOn.target ? addOn.target : "_self"}
                  onClick={addOn.onClick && addOn.onClick}
                >
                  <i className={`${addOn.icon} me-1`} />
                  {addOn.title}
                </Link>
              ) : (
                <button
                  className={`btn ${addOn.bg}`}
                  aria-disabled={addOn.disabled}
                  tabIndex={addOn.disabled ? "-1" : "0"}
                  onClick={addOn.onClick && addOn.onClick}
                  disabled={addOn.disabled ? true : false}
                >
                  <i className={`${addOn.icon} me-1`} />
                  {addOn.title}
                </button>
              )}
            </div>
          ))}

        {/* general export to excel */}
        {(subModuleId === productPCIId || asyncExport) && (
          <button
            type="button"
            aria-label="Load CSV Data"
            className="btn btn-secondary"
            onClick={() => setIsModal(true)}
          >
            {buttonsTitles.exportExcel}
          </button>
        )}

        {/* For Renewal Policies only */}
        {isBackPermission && backPageLink && (
          <div className="text-sm-end">
            <Link className="btn btn-secondary" to={backPageLink}>
              <i className="mdi mdi-keyboard-backspace me-1" />
              {buttonsTitles.back}
            </Link>
          </div>
        )}

        {/* Add Claim Reimbursement Icon Button */}
        {isAddClaimReimbursementPermission && addPageLink && (
          <div className="text-sm-end">
            <Link
              to={{
                pathname: addPageLink,
                state: { type_id: claimsTypes.reimbursement },
              }}
              className="btn btn-primary"
            >
              <i className="mdi mdi-plus me-1" />
              {isAddClaimReimbursementPermission?.name}
            </Link>
          </div>
        )}

        {/* Add Drop Down applied only in Invoice Module right now */}

        {isAddPermission && addDropDown && addDropDown}

        {/* Add Link */}
        {isAddPermission && addPageLink && (
          <div className="text-sm-end">
            <Link
              to={addPageLink}
              className="btn btn-success"
              target={isAddInNewTab ? "_blank" : "_self"}
            >
              <i className="mdi mdi-plus me-1" />
              {moduleId === claimBatchModuleId
                ? buttonsTitles.reimbursementBatch
                : buttonsTitles.addNew}
            </Link>
          </div>
        )}

        {/* Add Buttom */}
        {isAddPermission && !addPageLink && handleAdd && (
          <div className="text-sm-end">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleAdd}
              aria-label="Add New"
            >
              <i className="mdi mdi-plus me-1" />
              {buttonsTitles.addNew}
            </button>
          </div>
        )}
        {/*Reload Button*/}
        {includeReloadButton && (
          <div className="text-sm-end">
            <button className="btn btn-success" onClick={onReloadClick}>
              <i className="mdi mdi-reload me-1" />
              <span>{buttonsTitles.reload}</span>
            </button>
          </div>
        )}
        {/* Batch Upload Button */}
        {isBatchUploadPermission && batchPageLink && (
          <div className="text-sm-end">
            <Link to={batchPageLink} target="_blank" className="btn btn-info">
              <i className="mdi mdi-cloud-upload me-1" />
              {/* {t("Batch Upload")} */}
              {isBatchUploadPermission?.name}
            </Link>
          </div>
        )}

        {/* Export Button */}
        {(isTableExportPermission || inCludeExportButton) && (
          <div className="text-sm-end">
            <ExportTable
              data={data}
              sheetName={sheetName}
              customCSVHeader={customCSVHeader}
              customCSVData={customCSVData}
              customPDFHeader={customPDFHeader}
              customPDFData={customPDFData}
              columns={columns}
              // ref={ref}
            />
          </div>
        )}

        {/* Eye Icon button  (show / hide)*/}
        {isTableViewPermission && (
          <div className="text-sm-end">
            <ShowHideColumns
              showOptions={showOptions}
              setShowOptions={setShowOptions}
              fullColumns={fullColumns}
            />
          </div>
        )}

        {/* filter Icon Button */}
        {isTableFilterPermission && (
          <div className="text-sm-end">
            <button type="button" className="btn btn-primary">
              <i className="mdi mdi-filter font-size-14" />
            </button>
          </div>
        )}
      </div>

      {isModal && (
        <AysncExportCSVModal
          isOpen={isModal}
          sheetHeader={sheetHeader}
          setIsOpen={setIsModal}
          getSheetData={getSheetData}
          queryAddOn={queryAddOn}
          sheetName={sheetName}
          generateCustomData={generateCustomData}
        />
      )}
    </Fragment>
  );
};

export default withTranslation()(TableActionsButtons);

import i18n from "i18n"
export const buttonsTitles = {
  // beneficiary buttons
  FamilyCompute: "Family Compute",
  memberCompute: "Member Compute",
  addMember: "Add Member",
  viewSub: "View Dependencies",
  createPolicy: "Create Policy",
  savePremium: "Save Premium",
  printInstallments: "Print Installments",
  // policies
  addBroker: "Add Broker",
  computeAndValidate: "Compute & Validate",
  printCards: "Print Cards",
  specialImport: "Special Import",
  printPremium: "Print Premium",
  endorsementHistory: "Endrosement History",

  // policy renewal
  renew: "Renew",
  renewalOffer: "Download Renewal Offer",
  // contracts
  primaryContract: "Save As Primary Contract",
  //general
  save: "Save",
  saveDocs: "Save Documents",
  saveChanges: "Save Changes",
  loadMore: "Load More",
  scheduleSave: "Schedule Save",
  confirm: "Confirm",
  close: "Close",
  yes: "Yes",
  no: "No",
  simulateImport: "Simulate Import",
  downloadSample: "Download Sample",
  saveData: "Save Data",
  savingData: "Saving Data",
  upload: "Upload",
  fileUpload: "File Upload",
  actions: "Actions",
  back: "Back",
  copy: "Copy",
  print: "Print",
  actions: "Actions",
  addNew: "Add New",
  filter: "Filter",
  confirm: "Confirm",
  no: "No",
  understood: "Understood",
  show: "Show",
  add: "Add",
  edit: "Edit",
  import: "Import",
  validate: "Validate",
  invalidate: "Invalidate",
  delete: "Delete",
  selectAll: "Select All",
  unselectAll: "Unselect All",
  previous: "Previous",
  next: "Next",
  cancel: "Cancel",
  search: "Search",
  submit: "Submit",
  export: "Export",
  exportPDF: "Export To PDF",
  exportExcel: "Export To Excel",
  remove: "Remove",
  proceed: "Proceed",
  ok: "Ok",
  select: "Select",
  compute: "Compute",
  apply: "Apply",
  clear: "Clear",
  send: "Send",
  sendEmail: "Send Email",
  continue: "Continue",
  update: "Update",
  blackList: "BlackList",
  whiteList: "WhiteList",
  preview: "Preview",
  reload: "Reload",
  // claims

  sendWhatsapp: "Send Whatsapp",
  convertToIN: "Convert to IN",
  statusHistory: "Status History",
  viewProcessing: "View Processing",
  viewChecklist: "View Checklist",
  //product
  productSummary: "Product Summary",
  // product premium
  custsomAge: "Add Custom Age Bracket",
  ageBracket: " Age Bracket",
  updatePercentage: "Change Premiums",

  // product Plans
  generateIN: "Generate IN",
  autoGenerate: "Auto Generate",
  generateINNSSF: "Generate IN NSSF",
  generateAll: "Generate All",
  generateOUT: "Generate OUT",
  // claims
  approved: "Approve",
  saveAndAdju: "Save & Adjudicate",
  processed: "Process",
  unProcessed: "UnProcess",
  declined: "Reject",
  pending: "Pending",
  unApproved: "Reopen",
  forward: "Forward",
  validateMedicalRule: "Validate Medical Rule & Calculate",
  addQuota: "Add Item",

  viewProfile: "View Profile",
  medicalFile: "Medical File",
  focSpent: "FOC Spent",
  tableofBenefits: "Table of Benefits",
  viewNotes: "View Notes",
  viewPremiums: "View Premium",
  printContract: "Print Contract",
  printCard: "Print Card",
  chat: "Chat",
  //batch claim
  addGroup: "Direct Billing Batch",
  reimbursementBatch: "Reimbursement Batch ",
  //dynamic Report
  saveReport: i18n.t("Save Report"),
  updateReport: i18n.t("Update Report"),
  // payer agreements
  createProduct: "Create Product",
  createAgreement: "Create Agreement",
  viewAgreements: "View Agreements",
  copyAgreement: "Copy Agreement",
  createContract: "Create Contract",
  viewContracts: "View Contracts",
  AddNewFeesBrackets: "Add New Fees Brackets",

  // Eligibility Validation
  selectMember: "Select Member",

  previewCard: "Preview Card",

  // policy renewal
  changePlans: "Change Plans",
  customRenewal: "Custom Renewal",

  claimHistory: "Claim History",
}

export const staticLabels = {
  //broker
  commissionExceptions: "Commission Exceptions",
  // claim filters
  claimDisplay: "View",
  isOwn: "Show Own Authorizations",
  showAll: "Show All",
  // claims
  undo: "undo",

  //dyanmic Reports
  category: "Category",
  savedReports: "Saved Reports",
  percentageOfPremium: "Percentage Of Premium",
  flatFees: "Flat Fees",
  volumeRebate: "Volume Rebate",
  contactEmails: "Contact Emails",
  advancedInfo: "Advanced Information",
  bankDetails: "Bank Details",
  settings: "Settings",
  participationType: "Participation Type",
  excludedProviders: "Excluded Providers",
  conversationName: "Conversation name",
  chatAccessType: "Type",

  // Eligibility Validation
  eligibilityCheck: "Eligibility Check",
  eligibilityVerification: "Eligibility Verification",

  // cards
  leftSide: "Left Side",
  rightSide: "Right Side",
  others: "others",
  policy: "Policy",
  beneficary: "beneficary",
  availableFields: "Available Fields",
  name: "Name",
  code: "Code",
  benefitName: "Benefit Name",
  benefitGroup: "Benefit Group",
  waitingPeriod: "Waiting Period(Days)",

  //product TOB - limit tab
  limitType: "Limit Type",
  limitValue: "Limit Value",
  class: "Class",

  showIn$: "Show in $",

  // modules accordions
  grantView: "Grant View",

  reports: "Reports",
  //loss ratio report
  policies: "Policies",
  description: "Description",
  files: "Files",
}

export const notificationsTemplatefields = [
  {
    title: "Insurer Details",
    values: [
      { label: "First name", value: "first_name" },
      { label: "Last name", value: "last_name" },
      { label: "DOB", value: "date_of_birth" },
    ],
  },
  {
    title: "Policy Details",
    values: [
      { label: "issue date", value: "issue_date" },
      { label: "expirty date", value: "expirty_date" },
      { label: "card number", value: "card_number" },
    ],
  },
]

export const staticFieldLabels = {
  // dynamic report (step three) filters

  field: "Field",
  operator: "Operator",
  value: "Value",
  claims: "Claims",
  //premium validation
  effectiveData: "Effective Date",
  //premium validation
  totalNet: "Total Net",
  totalGross: "Total Gross",
  discount: "Discount %",
  discountAmount: "Discount Amount",
  issuingFees: "Issuing Fee",
  overPremium: "Over Premium",
  sumInsured: "Sum Insured",
  vatAmount: "Tax Amount",
  totalPremiums: "Total Premiums",

  // product premium
  digitalSign: "Digital Signature",
  changePercentage: "Change Percentage",

  // payer card
  color: "Font Color",
  paymentOrders: "Payment Orders",

  // general
  comments: "Comments",
  // receipt
  invoiceType: "Payment note Type",
  invoiceAmount: "Amount",
  // receipt transactions
  totalAmount: "Total Amount",
  dueBalance: "Due Balance",
  selectedAmount: "Selected Amount",
  receiptAmount: "Receipt Amount",

  // volume rebate report (top section)
  claimStatus: "Claim Status",
  fromToDate: "From Date - To Date",
  fromToEffectiveDate: "From Effective Date - To Effective Date",
  fromToIssueDate: "From Issue Date - To Issue Date",
  //accounting report
  fromToDischargeDate: "From Discharge Date - To Discharge Date",
  providerName: "Provider Name",
  contractName: "Contract Name",
  totalDiscouns: "Total Discount",
  totalManagement: "Total Management",
  //accordion inputs for volume rebate repor
  discountType: "Discount Type",
  totalClaimed: "Total Claimed Amount",
  proFees: "Professional Fees",
  medicine: "Medicine",
  totalExclusions: "Total Exclusions",
  netAmount: "Net Amount",

  //fees by beneficiary
  insurerName: "Insurer Name",
  agreements: "Agreements",
  amountSum: "Total Amount",

  // accordion inputs for fees by beneficiary
  type: "Type",
  benefit: "Benefit",
  fees$: "Fees",
  totalAmount$: "Total Amount",
  // Table of benefits
  TOBTemplate: "TOB Template",

  //accordion inputs for premium fees report
  percentage: "Percentage %",
  minAmount: "Min Amount",
  maxAmount: "MaxAmount",
  totalCalculatedAmount: "Total Calculated Amount",
  totalOverWriteAmount: "Total Overwrite Amount",

  // claim items
  grossClaimed: "Gross Claimed",
  grossApproved: "Gross Approved",
  grossDeclined: "Gross Declined",
  totalDenied: "Total Denied",
  copartAmount: "Copart Amount",
  deductibleAmount: "Deductible Amount",
  paidByPatient: "Paid By Patient",
  grossDiscount: "Gross Discount",
  grossPenalty: "Gross Penalty",
  physicianShare: "Physician Share",
  providerShare: "Provider Share",
  paidByPayer: "Paid By Insurer",
  // policy renewal
  sumOfPremiums: "Sum of Premiums",
  overPremiumAmount: "Over Premium Amount",
  policyIssueFees: "Policy Issue Fee",
  endDate: "End Date",
  inceptionDate: "Inception Date",
  productName: "Product Name",

  memberName: "Member Name",
  newpolicyHolder: "New Policy Holder",
  payerName: "Insurer Name",
  policyName: "Policy Name",
  priorityPayerName: "Priority Payer Name",
  brokerName: "Broker",
}

export const staticPlaceholders = {
  topSearchBar: "Search By Authorization No.",
  totalDiscountAmount: "Total Discount Amount",
  usd: "USD",
  amountPerMember: "Amount Per Member",
  selectDate: "Select Date",
  // product premiums
  percentage: "Enter Percentage",
}

export const modalHeaders = {
  // general
  warning: "Warning",
  medicalFile: "Medical Files",
  copyAgreement: "Copy Agreement",
  premiumValidation: "Premium Validation",
  //upload contract prices
  confirmLineOfBussiness: "Confirm Line of Bussiness",
  // Eligibility Validation
  verificationNotes: "Verification Notes",
  // compute and validate policy
  computePolicy: "Compute Policy",

  // premiums
  confirmUploadPremiums: "Confirm Upload Premiums",
  // renew policy
  renewalPolicy: "Renewal Policy",
  changePlans: "Change Plans",

  // premiums
  updatePercentage: "Update Percentage",
}

export const modalsStates = {
  update: "UPDATE_MODAL",
  add: "ADD_MODAL",
  view: "VIEW_MODAL",
  stakeholderStatus: "STATUS_MODAL",
  viewPremium: "VIEW_PREMIUM_MODAL",
  memberCompute: "MEMBER_COMPUTE_MODAL",
  familyCompute: "FAMILY_COMPUTE_MODAL",
  sepcialNotes: "SPECIAL_NOTES_MODAL",
  selectPlans: "SELECT_PLANS_MODAL",
  printInvoice: "PRINT_INVOICE_MODAL",
  endrosementModal: "ENDORSEMNET_MODAL",
  additionalModal: "ADDITIONAL_MODAL",
  policyPrintContract: "POLICY_SELECT_MEMBER_MODAL",
  medicalFilesModal: "MEDICAL_FILES_MODAL",
  networkModal: "NETWORK_MODAL",
  chatModal: "CHAT_MODAL",
  printCard: "PRINT_CARD_MODAL",
  whatAppModal: "WHATSAPP_MODAL",
  focSpent: "FOC_SPENT_MODAL",
}

export const staticMetaTitles = {
  claimValidation: "Claim Validation",
}

import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Label, UncontrolledTooltip } from "reactstrap";
import Select from "react-select";
import FormInputError from "./FormInputError";
import {
  apiErrorrHandler,
  generateToast,
  isItemObject,
  NotificationMessage,
} from "utils";
import DropDownSkeleton from "./DropDownSkeleton";
import { useLocation } from "react-router-dom";
import DropdownChangeModal from "../Modals/DropdownChangeModal";
import { BigPagination } from "constants/TPA";
import useSWR from "swr";
import { generalRequest } from "helpers/General/general_request";
import { compact, defaults } from "lodash";
import * as URLs from "helpers/url_helper";

const SearchSelect = ({
  id,
  name,
  style,
  value: propValue,
  inputField,
  label: propLabel,
  inputClass: propInputClass,
  labelClass: propLabelClass,
  placeholder: propPlaceholder,
  options,
  onChange,
  customOnChange,
  disableSearch,
  onBlur,
  invalid,
  error,
  getOptionsData,
  onSelectData,
  showPopupOnChange,
  urlAddOns,
  dynamicQuery,
  mappingLabel,
  mappingValue,
  wrapperClassNames,
  horizontalLayout,
  fixedMenu,
  multiple,
  noLabel,
  generateCustomLabels,
  hideSelectedOptions,
  recordId,
  onClear,
  formatOptionLabel,
  generateDisabledOptions,
  disableSorting,
  readOnly,
  validateOnSelect,
  onSelectValidateData,
  notBlurAfterSelect,
  selectOnMount,
  fetchAsync,
  generateFilteredOptions,
  selectAll,
  url,
  emptyList,
  disableFetch,
  specificKey,
  labelPrefix,
  labelSuffix,
  labelAddOns,
  selectFirstOption = false,
  ...rest
}) => {
  const location = useLocation();
  const { pathname } = location;
  // to know if the page link has a view word or not
  const pageType = pathname.split("/")[2];

  // Local States
  const [selectOptions, setSelectOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);

  const [counter, setCounter] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const fetcher = async (url, params) => {
    const isURLHasNum =
      compact(url.split("/").map((item) => +item)).length > 0 ? true : false;
    let additionalParams = isURLHasNum
      ? {}
      : disableSorting
      ? { paginate: 1000 }
      : {
          paginate: 1000,
          sort_type: "asc",
          sort_by: mappingLabel,
        };

    const res = await generalRequest({
      url,
      ...additionalParams,
      ...params,
    });

    if (res) {
      if (res.items) {
        const itemsOptions = inputField?.predefined_value
          ? res.items.children
          : res.items;
        // select the first option by default
        if (selectFirstOption && itemsOptions?.length > 0) {
          customOnChange
            ? customOnChange(itemsOptions[0], { action: "select" })
            : handleChange(itemsOptions[0], { action: "select" });
        }
        return itemsOptions;
      }
      if (res.item) {
        const itemOptions = inputField?.predefined_value
          ? res.item.children
          : specificKey
          ? res.item[specificKey]
          : res.item;
        // select the first option by default
        if (selectFirstOption && itemOptions?.length > 0) {
          handleChange(itemOptions[0], { action: "select" });
        }
        return itemOptions;
      }
    }
  };

  const params = defaults(urlAddOns, dynamicQuery);
  // const {
  //   isLoading: loading,
  //   error: err,
  //   data,
  // } = useSWR(
  //   () =>
  //     isPredefined
  //       ? [`${URLs.PREDEFINEDS}/${predefinedId}`, params]
  //       : url
  //       ? [url, params]
  //       : null,
  //   ([url, params]) => fetcher(url, params),
  //   {
  //     revalidateOnFocus: false,
  //   }
  // )
  const {
    isLoading: loading,
    error: err,
    data,
  } = useSWR(
    () => (url && !disableFetch ? [url, params] : null),
    ([url, params]) => fetcher(url, params),
    {
      revalidateOnFocus: false,
    }
  );

  const inputLabel =
    inputField && inputField.label_name ? inputField.label_name : propLabel;
  const placeholder =
    inputField && inputField.placeholder
      ? inputField.placeholder
      : propPlaceholder;
  const labelClass = `${
    inputField && inputField.label_class ? inputField.label_class : ""
  } ${propLabelClass ? propLabelClass : ""}`;

  const inputClass = `${
    inputField && inputField.input_class ? inputField.input_class : ""
  } ${propInputClass ? propInputClass : ""}`;

  const handleChange = (selectedValue, { action }) => {
    console.log("selected Value", selectedValue);
    console.log("selected Action", action);
    if (validateOnSelect && onSelectValidateData) {
      onSelectValidateData(name, selectedValue);
    }
    if (action === "clear" && onClear) {
      onClear();
    }
    if (multiple) {
      onChange(
        `${name}`,
        selectedValue.length > 0
          ? mappingValue
            ? selectedValue.map((item) => item[mappingValue])
            : selectedValue.map((item) => item.value)
          : []
      );
      if (onSelectData) {
        onSelectData(selectedValue);
      }
    } else {
      onChange(
        `${name}`,
        selectedValue
          ? mappingValue
            ? selectedValue[mappingValue]
            : selectedValue.value
          : ""
      );
      if (onSelectData) {
        onSelectData(selectedValue);
        if (!notBlurAfterSelect) {
          selectRef.current.blur();
        }
      }
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(`${name}`, true);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    let res;
    try {
      if (recordId) {
        res = await getOptionsData(recordId);
      } else {
        res = await getOptionsData({
          sort_type: !disableSorting ? "asc" : "",
          sort_by: !disableSorting ? mappingLabel : "",
          per_page: BigPagination,
          ...urlAddOns,
          ...dynamicQuery,
        });
      }
      if (res) {
        if (res.items) {
          setSelectOptions(
            inputField?.predefined_value ? res.items.children : res.items
          );
        }
        if (res.item) {
          setSelectOptions(
            inputField?.predefined_value ? res.item.children : res.item
          );
        }
      } else {
        setSelectOptions([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      const errorMessage = apiErrorrHandler(error);
      NotificationMessage("Error", errorMessage);
    }
  };

  // for fetching on page load
  useEffect(() => {
    if (
      !fetchAsync &&
      getOptionsData &&
      (mappingLabel || generateCustomLabels) &&
      mappingValue &&
      !url
    ) {
      getData();
    }
  }, [JSON.stringify(dynamicQuery), recordId, fetchAsync, url]);

  // for fetchiing async
  useEffect(() => {
    if (
      fetchAsync &&
      isOpen &&
      getOptionsData &&
      (mappingLabel || generateCustomLabels) &&
      mappingValue
    ) {
      getData();
    }
  }, [JSON.stringify(dynamicQuery), recordId, fetchAsync, isOpen]);

  const showToastNotification = () => {
    generateToast(
      "warning",
      "There Are some data will be changing after this action!",
      "Warning"
    );
  };

  useEffect(() => {
    if (
      selectOnMount &&
      propValue &&
      selectOptions.length > 0 &&
      counter === 1
    ) {
      const value = selectOptions.find(
        (item) => item[mappingValue] === propValue
      );
      selectRef?.current?.selectOption(value);
      setCounter(0);
    }
  }, [selectOnMount, propValue, selectOptions.length]);

  useEffect(() => {
    if (url && data) {
      setSelectOptions(
        specificKey ? compact(data.map((item) => item[specificKey])) : data
      );
      setIsLoading(loading);
    }
  }, [data, url, specificKey]);

  useEffect(() => {
    if (disableFetch) {
      setSelectOptions([]);
    }
  }, [disableFetch]);

  return (
    <div className={`${wrapperClassNames ? wrapperClassNames : ""}`}>
      {!noLabel && (
        <div className="d-flex align-items-start gap-2">
          <Fragment>
            <Label
              htmlFor={id}
              className={`text-capitalize  ${
                inputField?.is_required ? "required-label" : ""
              } ${labelClass ? labelClass : ""}`}
              // data-toggle="tooltip"
              // title={placeholder ? placeholder : ""}
              id={`tooltip-${id}`}
            >
              {labelPrefix && labelPrefix}
              {inputLabel}
              {labelSuffix && labelSuffix}
            </Label>
            {placeholder && (
              <UncontrolledTooltip placement="top" target={`tooltip-${id}`}>
                {placeholder}
              </UncontrolledTooltip>
            )}
          </Fragment>
          {/* Reload Button */}
          {pageType !== "view" ? (
            <Fragment>
              {labelClass.includes("reload-button") ? (
                <button
                  className="btn btn-transparent p-0 border-0 m-0 position-relative d-inline-block"
                  style={{ top: "-2px" }}
                  aria-label="Reload Options"
                  type="button"
                  data-toggle="tooltip"
                  title="Reload Options"
                  onClick={() => getData()}
                >
                  <i className="mdi mdi-reload font-size-18"></i>
                </button>
              ) : null}
              {/* Select All Button */}
              {selectAll ? (
                <button
                  className="btn btn-transparent p-0 border-0 m-0 position-relative d-inline-block"
                  style={{ top: "-2px" }}
                  aria-label="Select All options"
                  type="button"
                  data-toggle="tooltip"
                  title="Select All Options"
                  // onClick={() => handleChange(selectOptions, { action: "select" })}
                  onClick={() =>
                    handleChange(
                      generateFilteredOptions
                        ? generateFilteredOptions(selectOptions)
                        : options
                        ? options
                        : generateDisabledOptions
                        ? selectOptions.filter(
                            (item) => !generateDisabledOptions(item)
                          )
                        : selectOptions,
                      { action: "select" }
                    )
                  }
                >
                  <i className="mdi mdi-playlist-check font-size-18"></i>
                </button>
              ) : null}

              {labelAddOns && labelAddOns}
            </Fragment>
          ) : null}
        </div>
      )}

      <div className={horizontalLayout && inputClass}>
        {(isLoading && !fetchAsync) || loading ? (
          <DropDownSkeleton />
        ) : (
          <Select
            name={name}
            id={id}
            formatOptionLabel={
              generateCustomLabels
                ? (data) => generateCustomLabels(data)
                : (data) => (options ? data?.label : data[mappingLabel])
            }
            ref={selectRef}
            placeholder={placeholder}
            getOptionLabel={(e) => (options ? e.label : e[mappingLabel])}
            getOptionValue={(e) => (options ? e.value : e[mappingValue])}
            option
            // disable search based on porp or in view page
            isSearchable={
              disableSearch || pageType === "view" || readOnly ? false : true
            }
            value={
              selectOptions.length > 0
                ? multiple
                  ? selectOptions.filter((option) =>
                      propValue.includes(option[mappingValue])
                    )
                  : selectOptions.find(
                      (option) =>
                        option[mappingValue] === propValue && propValue
                    )
                : multiple
                ? options?.filter((option) => propValue.includes(option.value))
                : isItemObject(propValue)
                ? propValue
                : options?.find((option) => option.value === propValue)
            }
            onChange={customOnChange ? customOnChange : handleChange}
            onBlur={handleBlur}
            // onMenuOpen={() => setIsWarningModal(true)}
            onMenuOpen={() => {
              if (showPopupOnChange && pageType !== "view") {
                showToastNotification();
              }
              if (fetchAsync) {
                setIsOpen(!isOpen);
              }
            }}
            options={
              generateFilteredOptions
                ? generateFilteredOptions(selectOptions)
                : options
                ? options
                : selectOptions
            }
            {...rest}
            className={`select2-selection form-control p-0 ${
              invalid ? "border-danger" : ""
            } ${inputClass ? inputClass : ""}${
              pageType === "view" ? "border-0" : ""
            } ${readOnly ? "bg-gray-600 bg-opacity-10" : ""}`}
            // overide the default style
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
                minHeight: "30px",
                height:
                  multiple && Array.isArray(propValue) && propValue?.length > 1
                    ? "auto"
                    : "30px",
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              indicatorsContainer: (provided) => ({
                ...provided,
                height: "100%",
              }),
            }}
            menuPosition={fixedMenu && "fixed"}
            menuShouldBlockScroll={fixedMenu ? true : false}
            // menuPosition="fixed"
            // disable clear option in view page
            isClearable={pageType !== "view"}
            // hide the menu,sperator and dropdown icon in view page
            components={
              pageType === "view" && {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Menu: () => null,
              }
            }
            isDisabled={readOnly || pageType === "view" ? true : false}
            // multiple selection
            isMulti={multiple}
            hideSelectedOptions={hideSelectedOptions}
            isOptionDisabled={(option) =>
              generateDisabledOptions && generateDisabledOptions(option)
            }
            isLoading={isLoading && fetchAsync ? true : false}
            // formatOptionLabel={option =>
            //   formatOptionLabel && formatOptionLabel(option)
            // }
            closeMenuOnSelect={multiple ? false : true}
          />
        )}
        {/* Error Message */}
        {invalid && !isLoading && pageType !== "view" ? (
          <FormInputError error={error} selectError={true} />
        ) : null}
      </div>

      {/* Modal that show up when user want to select another option from select menu */}
      {isWarningModal && showPopupOnChange && (
        <DropdownChangeModal
          show={isWarningModal && showPopupOnChange}
          onCloseClick={() => {
            setIsWarningModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SearchSelect;
